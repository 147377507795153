import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from "../src/pages/Home";
import About from "../src/pages/About";
import Policy from "../src/pages/Policy";
import Privacy from "../src/pages/Privacy";
export const App = () => (
  <ChakraProvider theme={theme}>
     <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="policy" element={<Policy />} />
          <Route path="privacy" element={<Privacy />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
  </ChakraProvider>
)

function Layout() {
  return (
    <Outlet />
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
