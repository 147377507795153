import * as React from "react"
import {
  Box,
  Text,
  VStack,
  StackDivider
} from "@chakra-ui/react";
import TopNav from "../navigations/TopNav";
import FooterNav from  "../navigations/FooterNav";
export default function Index() {
  return (
    <VStack 
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
    >
      <Box h='40px' bg='gray'>
        <TopNav />
      </Box>
      <Box h='400px' bg='gray'>
        <Text>Home Page</Text>
      </Box>
      <Box h='40px' bg='gray'>
        <FooterNav />
      </Box>
  </VStack>
  )
}
