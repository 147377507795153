import * as React from "react"
import {
  Box,
  Flex
} from "@chakra-ui/react"
import { Link } from "react-router-dom";
export default function Index() {
  return (
    <Flex>
      <Box w='170px' h='10'  p='4' bg='green.400'>
        <Link to="/">Home</Link>
      </Box>
      <Box w='170px' h='10'  p='4' bg='green.400'>
        <Link to="/about">About</Link>
      </Box>
      <Box w='170px' h='10'  p='4' bg='green.400'>
        <Link to="/policy">Policy</Link>
      </Box>
      <Box w='170px' h='10'  p='4' bg='green.400'>
        <Link to="/privacy">Privacy</Link>
      </Box>
    </Flex>
  )
}
