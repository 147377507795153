import * as React from "react"
import {
  Box,
  Flex,
  Spacer
} from "@chakra-ui/react"
import { Link } from "react-router-dom";
export default function Index() {
  return (
    <Flex>
      <Box w='170px' h='10'  p='4' bg='red.400'>
        <Link to="/">Home</Link>
      </Box>
      <Spacer />
      <Box w='170px' h='10'  p='4' bg='red.400'>
        <Link to="/privacy">Privacy</Link>
      </Box>
    </Flex>
  )
}